import React, { useEffect, useMemo, useRef, useState } from "react";
import { isMobile } from "react-device-detect";

import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../store";
import { resetLineAndBranch } from "../../../store/dashboard/dashboardSlice";
import {
  chooseCompany,
  updateDefaultCompany,
} from "../../../store/user/userAction";
import { selectCompany } from "../../../store/user/userSlice";
import IconV2 from "../../shared/iconV2/IconV2";
import SearchInput from "../../shared/searchInput/SearchInput";

import {
  ActionWrapper,
  CheckWrapper,
  CompanyContent,
  CompanyItem,
  CompanyItemWrapper,
  CompanyShowWrapper,
  CompanyWrapper,
  IconHeightWrapper,
  IconWrapper,
  NoResultSubTitle,
  NoResultTitle,
  NoResultWrapper,
  SearchWrapper,
  SubTitle,
  Title,
  TitleWrapper,
  UnfoldMoreIconWrapper,
  Wrapper,
} from "./SelectCompany.style";

interface SelectCompanyProps {
  active: boolean;
  setActive: (state: boolean) => void;
}

const SelectCompany = ({ active, setActive }: SelectCompanyProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [searchValue, setSearchValue] = useState("");
  const [openCompany, setOpenCompany] = useState(false);
  const companyRef = useRef<HTMLDivElement>(null);

  const companies = useAppSelector((state) => state.user.user?.companies);
  const selectedCompany = useAppSelector((state) => state.user.selectedCompany);

  const defaultCompany = useAppSelector(
    (state) => state.user.user?.defaultCompanyId
  );

  const filteredCompanies = useMemo(() => {
    if (searchValue && companies?.length) {
      return companies.filter(
        (company) =>
          company.name.toUpperCase().includes(searchValue.toUpperCase()) ||
          company.identificationCode
            .toUpperCase()
            .includes(searchValue.toUpperCase())
      );
    }

    return companies || [];
  }, [searchValue, companies]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        companyRef?.current &&
        !companyRef.current.contains(event.target as Node)
      ) {
        setOpenCompany(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleWrapperClick = () => {
    if (!active) {
      setActive(true);
      setTimeout(() => {
        setOpenCompany((prev) => !prev);
      }, 300);
    } else {
      setOpenCompany((prev) => !prev);
    }
  };

  const handleSelectCompany = (companyId: number) => {
    setOpenCompany(false);
    setSearchValue("");
    dispatch(chooseCompany({ CompanyId: companyId })).then(() => {
      dispatch(selectCompany({ selectedCompanyId: companyId }));
      dispatch(resetLineAndBranch());
    });
  };

  const handleSelectDefault = (id: number | null): void => {
    dispatch(updateDefaultCompany({ defaultCompanyId: id }));
  };

  return (
    <Wrapper isMobile={isMobile} ref={companyRef} onClick={handleWrapperClick}>
      <IconWrapper>
        <IconV2 size="medium" iconName="company" />
      </IconWrapper>
      <CompanyShowWrapper active={active}>
        <TitleWrapper>
          <Title>{t("SelectCompany.Company")}</Title>
          <SubTitle>{selectedCompany?.name}</SubTitle>
        </TitleWrapper>
        <UnfoldMoreIconWrapper isMobile={isMobile}>
          <IconV2 size="medium" iconName="upDownArrows" />
        </UnfoldMoreIconWrapper>
      </CompanyShowWrapper>

      {openCompany && (
        <CompanyWrapper>
          <SearchWrapper>
            <SearchInput
              placeholder={t("SelectCompany.SearchInput")}
              value={searchValue}
              smallPlaceholder
              handleChange={(e) => {
                setSearchValue(e.target.value);
              }}
            />
          </SearchWrapper>
          <CompanyItemWrapper padding={filteredCompanies.length}>
            {filteredCompanies.length ? (
              <>
                {filteredCompanies?.map((company, index) => (
                  <CompanyItem
                    key={index}
                    onClick={(e: any) => {
                      e.stopPropagation();
                      handleSelectCompany(company.id);
                    }}
                    active={selectedCompany?.id === company.id}
                  >
                    <CompanyContent active={selectedCompany?.id === company.id}>
                      {company.name}
                    </CompanyContent>
                    <ActionWrapper>
                      <CheckWrapper>
                        {selectedCompany?.id === company.id ? (
                          <IconHeightWrapper>
                            <IconV2
                              fill="primary"
                              size="medium"
                              iconName="checkMark"
                            />
                          </IconHeightWrapper>
                        ) : null}
                      </CheckWrapper>
                      <div>
                        {defaultCompany === company.id ? (
                          <IconHeightWrapper>
                            <IconV2 size="medium" iconName="favFilled" />
                          </IconHeightWrapper>
                        ) : (
                          <IconHeightWrapper
                            onClick={(e) => {
                              e.stopPropagation();
                              handleSelectDefault(company.id);
                            }}
                          >
                            <IconV2 size="medium" iconName="fav" />
                          </IconHeightWrapper>
                        )}
                      </div>
                    </ActionWrapper>
                  </CompanyItem>
                ))}
              </>
            ) : (
              <NoResultWrapper>
                <IconV2 iconName="noResult" size="huge" />
                <NoResultTitle>{t("SelectCompany.NotFound")}</NoResultTitle>
                <NoResultSubTitle>
                  {t("SelectCompany.NotFoundDescription")}
                </NoResultSubTitle>
              </NoResultWrapper>
            )}
          </CompanyItemWrapper>
        </CompanyWrapper>
      )}
    </Wrapper>
  );
};

export default SelectCompany;
