import React, { useCallback, useState } from "react";

import {
  InfoWrapper,
  IconWrapper,
  MainListTitle,
  MainListWrapper,
  Wrapper,
  ButtonWrapper,
} from "./MappingType.style";

import { useTranslation } from "react-i18next";
import Icon from "../../../shared/iconV2/IconV2";
import {
  AccountMappingGroup,
  AccountMappingType,
  MappingItemType,
} from "../../../../store/mapping/mapping.types";
import MappingGroups from "../mappingGroups/MappingGroups";
import Button from "../../../v2/button/Button";
import InformationNumber from "../informationNumber/InformationNumber";
interface MappingTypeProps {
  openTypeId: number | null;
  openGroupId: number | null;
  accountMappingTypes: Array<AccountMappingType>;
  orderingDisable: boolean;
  setOpenGroupId: (state: number | null) => void;
  setOpenTypeId: (state: number | null) => void;
  handleOpenAddGroup: (value: number) => void;
  handleEditGroup: (group: AccountMappingGroup) => void;
  handleOpenEditItem: (
    mappingItem: MappingItemType,
    accountMappingType: AccountMappingType
  ) => void;
  handleDeleteGroup: (
    name: string,
    groupId: number,
    accountMappingTypeId: number
  ) => void;
  handleDeleteMappingItem: (
    id: number,
    name: string,
    sectionId: number,
    accountMappingTypeId: number
  ) => void;
  updateGroupsOrdering: (
    groupsIds: Array<number>,
    accountMappingTypeId: number
  ) => void;
  updateGroupMappingItemOrdering: (ids: Array<number>, groupId: number) => void;
}
const MappingType = ({
  openTypeId,
  openGroupId,
  accountMappingTypes,
  orderingDisable,
  setOpenTypeId,
  setOpenGroupId,
  handleOpenAddGroup,
  handleEditGroup,
  handleOpenEditItem,
  handleDeleteGroup,
  handleDeleteMappingItem,
  updateGroupsOrdering,
  updateGroupMappingItemOrdering,
}: MappingTypeProps) => {
  const { t } = useTranslation();

  const handleOpenSection = (id: number) => {
    if (openTypeId === id) {
      setOpenTypeId(null);
    } else {
      setOpenTypeId(id);
    }
  };

  const accountMappingNumber = useCallback(
    (accountMappingGroups: Array<AccountMappingGroup>): number => {
      return accountMappingGroups.reduce(
        (sum, group) => sum + group.accountMappings.length,
        0
      );
    },
    []
  );

  const warningAccountMappingNumber = useCallback(
    (accountMappingGroups: Array<AccountMappingGroup>): number => {
      return accountMappingGroups.reduce((sum, group) => {
        return (
          sum +
          group.accountMappings.filter(
            (item) =>
              !item.creditRePortalMappingId || !item.debitRePortalMappingId
          ).length
        );
      }, 0);
    },
    []
  );
  return (
    <Wrapper>
      {accountMappingTypes.map((item) => (
        <div key={item.accountMappingType.id}>
          <MainListWrapper
            onClick={() => handleOpenSection(item.accountMappingType.id)}
          >
            <MainListTitle>{item.accountMappingType.name}</MainListTitle>
            <InfoWrapper>
              <ButtonWrapper id="button_wrapper">
                <Button
                  rounded
                  text={t("MainMappingList.ButtonTitle")}
                  size="small"
                  buttonStyle="filled"
                  leftIconName="plus_v2"
                  handleClick={(e: any) => {
                    e.stopPropagation();

                    handleOpenAddGroup(item.accountMappingType.id);
                  }}
                />
              </ButtonWrapper>

              <InformationNumber
                warningQuantity={warningAccountMappingNumber(
                  item.accountMappingGroups
                )}
              />
              <InformationNumber
                quantity={accountMappingNumber(item.accountMappingGroups)}
              />
              <IconWrapper
                className={
                  openTypeId === item.accountMappingType.id ? "rotate" : ""
                }
              >
                <Icon iconName="arrowDown" size="medium" fill="grey" />
              </IconWrapper>
            </InfoWrapper>
          </MainListWrapper>
          {openTypeId === item.accountMappingType.id && (
            <MappingGroups
              openGroupId={openGroupId}
              orderingDisable={orderingDisable}
              groups={item.accountMappingGroups}
              handleOpenEditItem={(mappingItem) => {
                handleOpenEditItem(mappingItem, item);
              }}
              updateGroupsOrdering={(groups) => {
                updateGroupsOrdering(groups, item.accountMappingType.id);
              }}
              setOpenGroupId={setOpenGroupId}
              updateGroupMappingItemOrdering={updateGroupMappingItemOrdering}
              handleEditGroup={handleEditGroup}
              handleDeleteGroup={handleDeleteGroup}
              handleDeleteMappingItem={handleDeleteMappingItem}
            />
          )}
        </div>
      ))}
    </Wrapper>
  );
};

export default MappingType;
