import React, { useEffect, useMemo, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import {
  Wrapper,
  SelectField,
  SelectWrapper,
  HeaderWrapper,
  OptionItem,
  OptionsWrapper,
  Arrow,
  Label,
  OptionsBorder,
  OptionItemWrapper,
  ErrorWrapper,
  IconWrapper,
  ErrorText,
  SearchInput,
  CloseIconWrapper,
  TitleWrapper,
  Title,
  ActionWrapper,
} from "./Select.style";
import Icon from "../../../components/shared/iconV2/IconV2";
import { FieldHelperProps, FieldInputProps, FieldMetaProps } from "formik";
// import ButtonIcon from "../../shared/buttonIcon/ButtonIcon";

interface Data {
  value: string;
  label: string;
}

interface FormikFieldProps {
  field?: FieldInputProps<string>;
  meta?: FieldMetaProps<string>;
  helpers?: FieldHelperProps<string>;
}

interface SelectProps {
  label: string;
  options: Array<Data>;
  isDisabled?: boolean;
  selectedValue?: string;
  reversed?: boolean;
  errorText?: string;
  handleSelect?: (value: string) => void;
  onChange?: (value: string) => void;
}

type SelectFormikProps = SelectProps & FormikFieldProps;

const Select = ({
  label,
  options,
  isDisabled = false,
  selectedValue,
  reversed,
  errorText,
  field,
  handleSelect,
  onChange,
}: SelectFormikProps) => {
  const { t } = useTranslation();
  const [opened, setOpened] = useState(false);
  const selectRef = useRef<HTMLDivElement>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [searchingText, setSearchingText] = useState("");

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        selectRef?.current &&
        !selectRef.current.contains(event.target as Node)
      ) {
        setOpened(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClick = (e: React.MouseEvent) => {
    if (!isDisabled) {
      e.preventDefault();
      setOpened((prevVal) => !prevVal);
    }
  };

  const selectedLabel = useMemo(() => {
    const item = options.find(
      (option) => option.value === (field?.value || selectedValue)
    );
    return item?.label || "";
  }, [options, field, selectedValue]);

  const handleSelection = (value: string) => {
    if (field) {
      field.onChange({ target: { name: field.name, value } });
    } else if (handleSelect) {
      handleSelect(value);
    }
    if (onChange) {
      onChange(value);
    }
  };

  const filteredOptions = useMemo(() => {
    return options.filter((option) =>
      option.label.toLowerCase().includes(searchingText.toLowerCase())
    );
  }, [options, searchingText]);

  return (
    <Wrapper>
      <SelectField ref={selectRef} onClick={handleClick}>
        <SelectWrapper opened={opened}>
          <HeaderWrapper>
            <Label
              hasError={Boolean(errorText)}
              isDisabled={isDisabled}
              isFocused={selectedLabel !== "" || isFocused}
              htmlFor="input"
            >
              {label}
            </Label>

            {!searchingText && (
              <TitleWrapper>
                <Title isDisabled={isDisabled}>{t(selectedLabel)}</Title>
              </TitleWrapper>
            )}

            <SearchInput
              value={searchingText}
              onChange={(e) => {
                setSearchingText(e.target.value);
              }}
              onClick={(e) => {
                e.stopPropagation();
                setOpened(true);
              }}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            />
          </HeaderWrapper>
          <ActionWrapper>
            {selectedLabel && (
              <CloseIconWrapper
                onClick={() => {
                  handleSelection("");
                }}
              >
                <Icon iconName="closeIcon" size="normal" />
              </CloseIconWrapper>
            )}
            <Arrow className={opened ? "rotate" : ""}>
              <Icon iconName="arrowDown" size="medium" />
            </Arrow>
          </ActionWrapper>
        </SelectWrapper>
        <OptionsBorder reversed={Boolean(reversed)} opened={opened}>
          <OptionsWrapper opened={opened}>
            {filteredOptions.map((option) => (
              <OptionItemWrapper
                isMobile={isMobile}
                key={option.value}
                onClick={() => {
                  setSearchingText("");
                  handleSelection(option.value);
                }}
              >
                <OptionItem isSelected={selectedLabel === option.label}>
                  {option.label.includes(".") ? t(option.label) : option.label}
                  {selectedLabel === option.label && (
                    <Icon size="medium" fill="primary" iconName="checkMark" />
                  )}
                </OptionItem>
              </OptionItemWrapper>
            ))}
          </OptionsWrapper>
        </OptionsBorder>
      </SelectField>
      {Boolean(errorText) && (
        <ErrorWrapper>
          <IconWrapper>
            <Icon iconName="closeIcon" size="extraSmall" fill="white" />
          </IconWrapper>
          <ErrorText>{errorText}</ErrorText>
        </ErrorWrapper>
      )}
    </Wrapper>
  );
};

export default Select;
