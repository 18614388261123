import { useTranslation } from "react-i18next";

import { MappingItemType } from "../../../../store/mapping/mapping.types";
import {
  ContentWrapper,
  DottedArc,
  IconWrapper,
  ItemId,
  ItemTree,
  ItemTreeElement,
  ItemTreeElementContentWrapper,
  ItemTreeElementWrapper,
  ItemTreeIconWrapper,
  ItemTreeSubElement,
  ItemWrapper,
  Title,
  TitleWrapper,
} from "./MappingItems.style";
import {
  VariableSizeList as List,
  ListChildComponentProps,
} from "react-window";
import Icon from "../../../shared/iconV2/IconV2";
import MappingItemActions from "../../../v2/more/MappingItemActions";

interface MappingItemsDynamicContentProps {
  accountMappings: Array<MappingItemType>;
  groupId: number;
  handleOpenEditItem: (mappingItem: MappingItemType) => void;
  handleDeleteMappingItem: (id: number, name: string, groupId: number) => void;
}

const Row = ({
  accountMappings,
  handleOpenEditItem,
  handleDeleteMappingItem,
  groupId,
  index,
  style,
}: ListChildComponentProps & MappingItemsDynamicContentProps) => {
  const { t } = useTranslation();

  const item = accountMappings[index];

  return (
    <div style={style}>
      <ItemWrapper key={item.id}>
        {/* <IconWrapper>
          <Icon iconName="drag_v2" size="medium" />
        </IconWrapper> */}
        <ContentWrapper>
          <div>
            <TitleWrapper>
              <ItemId>{item.accountId}</ItemId> - <Title> {item.name}</Title>
            </TitleWrapper>
            <ItemTree>
              {item.debitRePortalMapping?.name && (
                <ItemTreeElementWrapper>
                  <ItemTreeIconWrapper>
                    <DottedArc />
                  </ItemTreeIconWrapper>
                  <ItemTreeElementContentWrapper>
                    <ItemTreeElement>{t("Mapping.DebitTitle")}</ItemTreeElement>
                    <ItemTreeSubElement>
                      {item.debitRePortalMapping?.name}
                    </ItemTreeSubElement>
                  </ItemTreeElementContentWrapper>
                </ItemTreeElementWrapper>
              )}
              {item.creditRePortalMapping?.name && (
                <ItemTreeElementWrapper>
                  <ItemTreeIconWrapper>
                    <DottedArc />
                  </ItemTreeIconWrapper>
                  <ItemTreeElementContentWrapper>
                    <ItemTreeElement>
                      {t("Mapping.CreditTitle")}
                    </ItemTreeElement>
                    <ItemTreeSubElement>
                      {item.creditRePortalMapping?.name}
                    </ItemTreeSubElement>
                  </ItemTreeElementContentWrapper>
                </ItemTreeElementWrapper>
              )}
              {item.companyBranch?.name && (
                <ItemTreeElementWrapper>
                  <ItemTreeIconWrapper>
                    <DottedArc />
                  </ItemTreeIconWrapper>
                  <ItemTreeElementContentWrapper>
                    <ItemTreeElement>{t("Mapping.Branch")}</ItemTreeElement>
                    <ItemTreeSubElement>
                      {item.companyBranch?.name}
                    </ItemTreeSubElement>
                  </ItemTreeElementContentWrapper>
                </ItemTreeElementWrapper>
              )}
              {item.companyBusinessLine?.name && (
                <ItemTreeElementWrapper>
                  <ItemTreeIconWrapper>
                    <DottedArc />
                  </ItemTreeIconWrapper>
                  <ItemTreeElementContentWrapper>
                    <ItemTreeElement>
                      {t("Mapping.BusinessLine")}
                    </ItemTreeElement>
                    <ItemTreeSubElement>
                      {item.companyBusinessLine?.name}
                    </ItemTreeSubElement>
                  </ItemTreeElementContentWrapper>
                </ItemTreeElementWrapper>
              )}
            </ItemTree>
          </div>
        </ContentWrapper>
        <MappingItemActions
          handleEdit={() => {
            handleOpenEditItem(item);
          }}
          handleDelete={() => {
            handleDeleteMappingItem(item.id, item.name, groupId);
          }}
        />
      </ItemWrapper>
    </div>
  );
};

const getItemSize = (
  index: number,
  accountMappings: Array<MappingItemType>
) => {
  const item = accountMappings[index];
  let baseHeight = 85; // Set a base height for all rows
  if (item.debitRePortalMapping?.name) baseHeight += 22.5;
  if (item.creditRePortalMapping?.name) baseHeight += 22.5;
  if (item.companyBranch?.name) baseHeight += 22.5;
  if (item.companyBusinessLine?.name) baseHeight += 22.5;

  return baseHeight;
};

const MappingItemsDynamicContent = ({
  accountMappings,
  groupId,
  handleOpenEditItem,
  handleDeleteMappingItem,
}: MappingItemsDynamicContentProps) => {
  return (
    <List
      height={700}
      itemCount={accountMappings.length}
      itemSize={(index) => getItemSize(index, accountMappings)}
      width={"100%"}
    >
      {({ index, style }) => (
        <Row
          data={[]}
          groupId={groupId}
          accountMappings={accountMappings}
          handleOpenEditItem={handleOpenEditItem}
          handleDeleteMappingItem={handleDeleteMappingItem}
          style={style}
          index={index}
        />
      )}
    </List>
  );
};

export default MappingItemsDynamicContent;
