import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../store";

import {
  ArrowWrapper,
  SideBarNavigationItemsWrapper,
  StraightBorder,
  Wrapper,
} from "./SideBar.style";
import SideBarNavigationItem from "./sideBarNavigationItem/SideBarNavigationItem";
import { useLocation, useNavigate } from "react-router-dom";

import IconV2 from "../../shared/iconV2/IconV2";
import { getCompanyInfo } from "../../../store/company/companyAction";
import { checkPermissionForRoute } from "../../../helpers/helper";
import { PageURL } from "../../../constants/router";
import SelectCompany from "../selectCompany/SelectCompany";
import SideBarNavigationWithDropdown from "./sideBarNavigationWithDropdown/SideBarNavigationWithDropdown";

const SideBar = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [opened, setIsOpened] = useState(true);

  useEffect(() => {
    dispatch(getCompanyInfo());
  }, [dispatch]);

  const user = useAppSelector((state) => state.user.user);

  const checkPermission = (path: string) => {
    if (user) {
      return checkPermissionForRoute(path, PageURL, user.permissions);
    }
    return false;
  };

  const handleClick = () => {
    setIsOpened((prev) => !prev);
  };

  return (
    <Wrapper active={opened}>
      <ArrowWrapper className={opened ? "rotate" : ""} onClick={handleClick}>
        <IconV2 iconName="arrowDown" />
      </ArrowWrapper>
      <SideBarNavigationItemsWrapper>
        <SelectCompany active={opened} setActive={setIsOpened} />
        {checkPermission(PageURL.Revenue.path) && (
          <SideBarNavigationItem
            active={opened}
            handleNavigate={() => {
              navigate(PageURL.Revenue.path);
            }}
            isSelected={location.pathname.includes(PageURL.Revenue.path)}
            iconName={"revenue"}
            pageName={t("SideBar.Revenue")}
          />
        )}
        {checkPermission(PageURL.Expenses.path) && (
          <SideBarNavigationItem
            active={opened}
            handleNavigate={() => {
              navigate(PageURL.Expenses.path);
            }}
            isSelected={location.pathname.includes(PageURL.Expenses.path)}
            iconName={"expenses"}
            pageName={t("SideBar.Expenses")}
          />
        )}
        {checkPermission(PageURL.ProfitLoss.path) && (
          <SideBarNavigationItem
            active={opened}
            handleNavigate={() => {
              navigate(PageURL.ProfitLoss.path);
            }}
            isSelected={location.pathname.includes(PageURL.ProfitLoss.path)}
            iconName={"profitLoss"}
            pageName={t("SideBar.ProfitLoss")}
          />
        )}
        {checkPermission(PageURL.CashFlow.path) && (
          <SideBarNavigationItem
            active={opened}
            handleNavigate={() => {
              navigate(PageURL.CashFlow.path);
            }}
            isSelected={location.pathname.includes(PageURL.CashFlow.path)}
            iconName={"cashFlow"}
            pageName={t("SideBar.CashFlow")}
          />
        )}
        {checkPermission(PageURL.Reportal.path) && (
          <SideBarNavigationItem
            active={opened}
            handleNavigate={() => {
              navigate(PageURL.Reportal.path);
            }}
            isSelected={location.pathname.includes(PageURL.Reportal.path)}
            iconName={"reportal"}
            pageName={t("SideBar.Reportal")}
          />
        )}
        {checkPermission(PageURL.RevenueServices.path) && (
          <SideBarNavigationItem
            active={opened}
            handleNavigate={() => {
              navigate(PageURL.RevenueServices.path);
            }}
            isSelected={location.pathname.includes(
              PageURL.RevenueServices.path
            )}
            iconName={"rsGe"}
            pageName={t("SideBar.RevenueService")}
          />
        )}
        {checkPermission(PageURL.Reports.path) && (
          <SideBarNavigationItem
            active={opened}
            handleNavigate={() => {
              navigate(PageURL.Reports.path);
            }}
            isSelected={location.pathname.includes(PageURL.Reports.path)}
            iconName={"report"}
            pageName={t("SideBar.Reports")}
          />
        )}
        {checkPermission(PageURL.Market.path) && (
          <SideBarNavigationItem
            active={opened}
            handleNavigate={() => {
              navigate(PageURL.Market.path);
            }}
            isSelected={location.pathname.includes(PageURL.Market.path)}
            iconName={"market"}
            pageName={t("SideBar.Market")}
          />
        )}
        <SideBarNavigationWithDropdown setOpen={setIsOpened} active={opened} />
      </SideBarNavigationItemsWrapper>
      <div>
        <StraightBorder />
        <SideBarNavigationItem
          active={opened}
          handleNavigate={() => {
            navigate(PageURL.FAQ.path);
          }}
          isSelected={location.pathname.includes(PageURL.FAQ.path)}
          iconName={"help"}
          pageName={t("SideBar.HelpCenter")}
        />
      </div>
    </Wrapper>
  );
};

export default SideBar;
