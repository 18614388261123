import React, { useState } from "react";
import { Wrapper } from "./ButtonIcon.style";
import Icon, { IconNameOptions } from "../iconV2/IconV2";

interface IconButton {
  iconName: IconNameOptions;
  size?: "normal" | "small";
  handleClick: () => void;
}

const ButtonIcon = ({ iconName, size = "normal", handleClick }: IconButton) => {
  const [focused, setFocused] = useState(false);

  return (
    <Wrapper
      onClick={handleClick}
      size={size}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
    >
      <Icon
        iconName={iconName}
        size={size}
        fill={focused ? "white" : "lightBlack"}
      />
    </Wrapper>
  );
};

export default ButtonIcon;
