import React from "react";
import {
  ButtonWrapper,
  IconWrapper,
  SubTitle,
  Title,
  TitleWrapper,
  Wrapper,
} from "./Success.style";

import { useTranslation } from "react-i18next";
import Icon from "../../../../../shared/iconV2/IconV2";
import Button from "../../../../../v2/button/Button";
import { LoaderType } from "../../../../../../store/type";

interface IProps {
  fetchingStatus: LoaderType;
  handleMappingItem: () => void;
  handleReturnMapping: () => void;
}

const Success = ({
  fetchingStatus,
  handleMappingItem,
  handleReturnMapping,
}: IProps) => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <TitleWrapper>
        <IconWrapper>
          <Icon iconName="checkMark" size="large" fill="white" />
        </IconWrapper>
        <div>
          <Title>{t("WizardSuccessPage.Title")}</Title>
          <SubTitle>{t("WizardSuccessPage.SubTitle")}</SubTitle>
        </div>
      </TitleWrapper>
      <ButtonWrapper>
        <Button
          loading={fetchingStatus === "pending"}
          text={t("WizardSuccessPage.MapAnotherItemButton")}
          size="large"
          buttonStyle={"filled"}
          handleClick={handleMappingItem}
        />
        <Button
          text={t("WizardSuccessPage.BackToMapping")}
          size="large"
          buttonStyle="ghost"
          handleClick={handleReturnMapping}
        />
      </ButtonWrapper>
    </Wrapper>
  );
};

export default Success;
