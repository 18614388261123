import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { ReactComponent as RightArrowSvg } from "../../../assets/svgs/smallRightArrow.svg";

export const Wrapper = styled.div(
  ({ theme }) => css`
    width: 100%;
    border-radius: 15px;
    background-color: ${theme.colors.white};
    overflow: hidden;
  `
);

export const Item = styled.div(
  ({ theme }) => css`
    cursor: pointer;
    padding: 20px 14px;
    border-bottom: 1px solid ${theme.colors.lightGrey};
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:last-child {
      border-radius: 0;
    }
  `
);

export const LightContent = styled.h6(
  ({ theme }) => css`
    ${theme.fonts.AvenirMedium}
    font-size: 12px;
    color: ${theme.colors.grey};
  `
);

export const RightSide = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;

export const RightArrow = styled(RightArrowSvg)(
  ({ theme }) => css`
    fill: ${theme.colors.black};
  `
);

export const BottomWrapper = styled.div`
  padding: 14px 14px 20px;

`;
