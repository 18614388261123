import React, { useRef, useEffect, useState } from "react";
import {
  OptionItem,
  OptionItemWrapper,
  PopUpContent,
  DotsSvg,
  Wrapper,
  DotsWrapper,
} from "./Options.style";
import { useTranslation } from "react-i18next";
import Icon from "../icon/Icon";

interface OptionsProps {
  isMobile: boolean;
  showDeleteItem?: boolean;
  handleEdit: () => void;
  handleDelete: () => void;
}

const Options = ({
  isMobile,
  showDeleteItem = true,
  handleEdit,
  handleDelete,
}: OptionsProps) => {
  const { t } = useTranslation();
  const wrapperRef = useRef<HTMLDivElement>(null);

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  return (
    <Wrapper ref={wrapperRef}>
      <DotsWrapper
        show={isOpen}
        onClick={(e) => {
          setIsOpen(true);
          e.stopPropagation();
        }}
      >
        <DotsSvg show={isOpen} />
      </DotsWrapper>
      {isOpen && (
        <PopUpContent isMobile={isMobile}>
          <OptionItemWrapper
            isMobile={isMobile}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleEdit();
              setIsOpen(false);
            }}
          >
            <Icon iconName="edit" />
            <OptionItem>{t("Options.Edit")}</OptionItem>
          </OptionItemWrapper>
          {showDeleteItem && (
            <OptionItemWrapper
              isMobile={isMobile}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleDelete();
                setIsOpen(false);
              }}
            >
              <Icon iconName="deleteIcon" />
              <OptionItem color={"dangerMain"}>
                {t("Options.Delete")}
              </OptionItem>
            </OptionItemWrapper>
          )}
        </PopUpContent>
      )}
    </Wrapper>
  );
};

export default Options;
