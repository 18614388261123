import React from "react";

import CustomIcons from "./IconSvgs";

const sizeMap: Record<string, { width: number; height: number }> = {
  extraSmall: { width: 9, height: 9 },
  micro: { width: 12, height: 12 },
  tiny: { width: 16, height: 16 },
  small: { width: 17, height: 17 },
  normal: { width: 20, height: 20 },
  medium: { width: 24, height: 24 },
  large: { width: 30, height: 30 },
  huge: { width: 56, height: 56 },
};

const colorMap: Record<string, { color: any }> = {
  primary: { color: "#5154C0" },
  default: { color: "#383838" },
  lightBlack: { color: "#454545" },
  danger: { color: "#EC1818" },
  white: { color: "#fff" },
  disabled: { color: "#BDBDBD " },
  grey: { color: "#989898" },
};
export type IconNameOptions = keyof typeof CustomIcons;

export interface CustomIconProps {
  iconName: IconNameOptions;
  size?:
    | "medium"
    | "small"
    | "large"
    | "normal"
    | "tiny"
    | "huge"
    | "extraSmall"
    | "micro";
  fill?:
    | "primary"
    | "default"
    | "danger"
    | "white"
    | "disabled"
    | "grey"
    | "lightBlack";
}

const Icon = ({
  iconName,
  size = "small",
  fill = "default",
}: CustomIconProps) => {
  const IconComponent = CustomIcons[iconName];

  const { width, height } = sizeMap[size];
  const { color } = colorMap[fill];

  if (!IconComponent) {
    return null;
  }

  return <IconComponent fill={color} width={width} height={height} />;
};

export default Icon;
