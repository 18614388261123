import { t } from "i18next";
import { useEffect, useRef, useState } from "react";

import Icon from "../../shared/iconV2/IconV2";
import {
  MoreContainer,
  MoreContainerItem,
  MoreContainerItemContent,
  MoreIconWrapper,
} from "./MappingItemActions.style";
interface MappingItemActionsProps {
  hasDelete?: boolean;
  handleEdit: () => void;
  handleDelete: () => void;
}
const MappingItemActions = ({
  hasDelete = true,
  handleEdit,
  handleDelete,
}: MappingItemActionsProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const optionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        optionRef?.current &&
        !optionRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    if (optionRef?.current) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [optionRef]);
  return (
    <>
      <MoreIconWrapper
        ref={optionRef}
        onClick={(e) => {
          e.stopPropagation();
          setIsOpen((prev) => !prev);
        }}
      >
        <Icon iconName="dots" size="tiny" fill="grey" />
        {isOpen && (
          <MoreContainer>
            <MoreContainerItem
              onClick={(e) => {
                e.preventDefault();
                handleEdit();
              }}
            >
              <Icon size="normal" iconName="pen" />
              <MoreContainerItemContent>
                {t("MappingItem.EditTitle")}
              </MoreContainerItemContent>
            </MoreContainerItem>
            {hasDelete && (
              <MoreContainerItem
                onClick={(e) => {
                  e.preventDefault();
                  handleDelete();
                }}
              >
                <Icon size="normal" iconName="trash_v2" fill="danger" />
                <MoreContainerItemContent isDelete>
                  {t("MappingItem.DeleteTitle")}
                </MoreContainerItemContent>
              </MoreContainerItem>
            )}
          </MoreContainer>
        )}
      </MoreIconWrapper>
    </>
  );
};
export default MappingItemActions;
