import { ChangeEvent, useState } from "react";
import IconV2 from "../iconV2/IconV2";

import { Box, CustomInput, SearchWrapper } from "./SearchInput.style";

interface SearchInputProps {
  value: string;
  placeholder: string;
  isWhite?: boolean;
  iconSize?: "small" | "medium" | "large";
  inputSize?: "small" | "medium" | "large";
  smallPlaceholder?: boolean;
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

const SearchInput = ({
  placeholder,
  value,
  isWhite = false,
  iconSize = "medium",
  inputSize = "medium",
  smallPlaceholder = false,
  handleChange,
}: SearchInputProps) => {
  const [focused, setFocused] = useState(false);

  const handleBlur = () => {
    setFocused(false);
  };

  const handleFocus = () => {
    setFocused(true);
  };

  return (
    <Box
      onBlur={() => {
        handleBlur();
      }}
      onFocus={() => {
        handleFocus();
      }}
    >
      <SearchWrapper size={inputSize}>
        <IconV2 iconName="searchSmall" size={iconSize} />
      </SearchWrapper>
      <CustomInput
        id="CompanySearch"
        smallPlaceholder={smallPlaceholder}
        hasBackground={isWhite}
        placeholder={placeholder}
        disabled={false}
        hasValue={Boolean(value)}
        value={value}
        isFocused={focused}
        inputSize={inputSize}
        onClick={(e) => {
          e.stopPropagation();
        }}
        onChange={handleChange}
      />
    </Box>
  );
};

export default SearchInput;
