import React, { useEffect, useRef, useState } from "react";
import { Languages } from "../../../enums/enum";
import { isMobile } from "react-device-detect";

import IconV2 from "../../shared/iconV2/IconV2";
import {
  Wrapper,
  ContentWrapper,
  ArrowWrapper,
  Content,
  DropDownContent,
  DropDownItem,
  DropDownTitle,
} from "./LanguageSwitcher.style";

interface LanguageSwitcherProps {
  language: any;
  handleChangeLanguage: (lang: Languages) => void;
}
const LanguageSwitcher = ({
  language,
  handleChangeLanguage,
}: LanguageSwitcherProps) => {
  const [open, setOpen] = useState(false);

  const languageSwitcherRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        languageSwitcherRef?.current &&
        !languageSwitcherRef.current.contains(event.target as Node)
      ) {
        setOpen(false);
      }
    };

    if (languageSwitcherRef?.current) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [languageSwitcherRef]);

  return (
    <Wrapper
      isMobile={isMobile}
      ref={languageSwitcherRef}
      opened={open}
      onClick={() => setOpen((prev) => !prev)}
    >
      <IconV2 size="medium" iconName="language" />
      <ContentWrapper>
        <Content>{language.toUpperCase()}</Content>
        <ArrowWrapper className={open ? "rotate" : ""}>
          <IconV2 size="medium" iconName="arrowDown" />
        </ArrowWrapper>
      </ContentWrapper>
      {open && (
        <DropDownContent>
          <DropDownItem
            isMobile={isMobile}
            onClick={() => {
              handleChangeLanguage(Languages.En);
            }}
          >
            <IconV2 iconName="en" size="medium" />
            <DropDownTitle>English</DropDownTitle>
          </DropDownItem>
          <DropDownItem
            isMobile={isMobile}
            onClick={() => {
              handleChangeLanguage(Languages.Ge);
            }}
          >
            <IconV2 iconName="ge" size="medium" />
            <DropDownTitle>ქართული</DropDownTitle>
          </DropDownItem>
        </DropDownContent>
      )}
    </Wrapper>
  );
};

export default LanguageSwitcher;
