import { css } from "@emotion/react";
import styled from "@emotion/styled";

interface WrapperProps {
  size: "large" | "medium" | "small";
  buttonStyle: "filled" | "stroke" | "lighter" | "ghost";
  rounded: boolean;
  disabled: boolean;
  center: boolean;
  fullWidth: boolean;
}

export const Wrapper = styled.button<WrapperProps>(
  ({ size, buttonStyle, theme, disabled, rounded, fullWidth, center }) => css`
    border-radius: ${rounded ? "100px !important" : "8px"};
    display: flex;
    align-items: center;
    justify-content: center;
    ${size === "large" &&
    css`
      padding: 14px 16px;
    `}
    ${size === "medium" &&
    css`
      padding: 8px;
    `}
   ${fullWidth &&
    css`
      width: 100%;
    `}
${size === "small" &&
    css`
      padding: ${rounded ? "4px 8px" : "6px"};
      border-radius: 6px;
    `}
${buttonStyle === "filled" &&
    !disabled &&
    css`
      background: ${theme.colors.primary600};
      &:hover {
        background: ${theme.colors.primary800};
      }
    `}
${buttonStyle === "stroke" &&
    !disabled &&
    css`
      border: 1px solid ${theme.colors.primary600};
      &:hover {
        background: ${theme.colors.primary100};
        border: 1px solid ${theme.colors.primary100};
      }
      &:active {
        border: 1px solid ${theme.colors.primary600};
        background: ${theme.colors.white};
      }
    `}
${buttonStyle === "lighter" &&
    !disabled &&
    css`
      background: ${theme.colors.primary100};
      &:hover {
        background: ${theme.colors.primary200};
      }
    `}
${buttonStyle === "ghost" &&
    !disabled &&
    css`
      background-color: transparent;
      border: 1px solid transparent;
      &:hover {
        background: ${theme.colors.primary100};
      }
      &:active {
        border: 1px solid ${theme.colors.primary600};
        background: ${theme.colors.white};
      }
    `}
${disabled
      ? css`
          background: ${theme.colors.greyShade100};
        `
      : css`
          cursor: pointer;
        `}
&:active {
      box-shadow: 0px 0px 0px 2px #fff, 0px 0px 0px 4px rgba(81, 84, 192, 0.08);
    }
    ${center &&
    css`
      justify-content: center;
    `}
    /* button loader spin */
   .loading-svg {
      animation: spin 1s linear infinite;
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  `
);
export const Content = styled.div<{
  buttonStyle: "filled" | "stroke" | "lighter" | "ghost";
  rounded: boolean;
  disabled: boolean;
}>(
  ({ theme, buttonStyle, disabled, rounded }) => css`
    ${theme.fonts.HelveticaNeue}
    padding:${rounded ? "0 4px" : "0 8px"};
    font-size: ${rounded ? "12px" : "14px"};
    line-height: ${rounded ? "normal" : "20px"};
    ${disabled
      ? css`
          color: ${theme.colors.grey500};
        `
      : css`
          color: ${buttonStyle !== "filled"
            ? theme.colors.primary600
            : theme.colors.white};
        `}
  `
);

export const IconWrapper = styled.div`
  width: 20px;
  height: 20px;
`;

export const ContentIconWrapper = styled.div<{ rounded: boolean }>(
  ({ rounded }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    ${rounded
      ? css`
          width: 16px;
          height: 16px;
        `
      : css`
          width: 20px;
          height: 20px;
        `}
  `
);
