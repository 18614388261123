import moment from "moment";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  BottomSheetType,
  ChartGroupTypes,
  PeriodType,
  RangeType,
} from "../../../enums/enum";
import { getDateRange } from "../../../helpers/helper";
import { useAppDispatch, useAppSelector } from "../../../store";
import {
  getCashFlowInfo,
  getExpensesInfo,
  getProfitLossInfo,
  getRevenueInfo,
} from "../../../store/dashboard/dashboardAction";
import {
  resetGroupeName,
  updateFilter,
  updateFrontSideFilter,
} from "../../../store/dashboard/dashboardSlice";
import { setBottomSheet } from "../../../store/user/userSlice";

import BottomSheet from "../../mobile/bottomSheet/BottomSheet";
import IconV2 from "../../shared/iconV2/IconV2";
import Logo from "../../shared/logo/Logo";
import {
  ArrowWrapper,
  BurgerBarWrapper,
  ContentWrapper,
  DropDownWrapper,
  Wrapper,
} from "./HeaderMobile.style";

const HeaderMobile = () => {
  const dispatch = useAppDispatch();
  const bottomSheet = useAppSelector((state) => state.user.bottomSheet);
  const selectedCompany = useAppSelector((state) => state.user.selectedCompany);

  const frontSideFilter = useAppSelector(
    (state) => state.dashboard.frontSideFilter
  );

  const submittedFilter = useAppSelector((state) => state.dashboard.filter);

  const location = useLocation();

  const [filterGroupType, setFilterGroupType] = useState(frontSideFilter.group);
  const [filterLines, setFilterLines] = useState(frontSideFilter.lines);
  const [filterBranches, setFilterBranches] = useState(
    frontSideFilter.branches
  );
  const [filterGroupNames, setFilterGroupNames] = useState(
    frontSideFilter.groupNames
  );
  const [pageLocation, setPageLocation] = useState<"Revenue" | "Expenses" | "">(
    ""
  );
  const [filterRangeType, setFilterRangeType] = useState(0);
  const [showViewFilter, setShowViewFilter] = useState(false);
  const [showBranchAndLineFilter, setShowBranchAndLineFilter] = useState(false);

  const [filter, setFilter] = useState({
    periodType: PeriodType.Month,
    fromDate: moment().subtract(1, "year").format(),
    toDate: moment().format(),
  });

  const [isChanged, setIsChanged] = useState({
    periodType: false,
    date: false,
    group: false,
    line: false,
    branch: false,
    groupName: false,
  });

  useEffect(() => {
    setFilterGroupNames(frontSideFilter.groupNames);
  }, [frontSideFilter.groupNames]);

  useEffect(() => {
    if (
      submittedFilter.fromDate &&
      submittedFilter.toDate &&
      submittedFilter.periodType
    ) {
      setIsChanged({
        date: false,
        group: false,
        periodType: false,
        line: false,
        branch: false,
        groupName: false,
      });

      dispatch(resetGroupeName());

      if (location.pathname === "/app/Revenue") {
        setShowViewFilter(true);
        setShowBranchAndLineFilter(true);
        dispatch(getRevenueInfo(submittedFilter as any));
        setPageLocation("Revenue");
      }
      if (location.pathname === "/app/Expenses") {
        setShowViewFilter(true);
        setShowBranchAndLineFilter(true);
        dispatch(getExpensesInfo(submittedFilter as any));
        setPageLocation("Expenses");
      }
      if (location.pathname === "/app/ProfitLoss") {
        setShowViewFilter(false);
        setShowBranchAndLineFilter(false);
        dispatch(getProfitLossInfo(submittedFilter as any));
        setPageLocation("");
      }
      if (location.pathname === "/app/CashFlow") {
        setShowViewFilter(false);
        setShowBranchAndLineFilter(false);
        dispatch(getCashFlowInfo(submittedFilter as any));
        setPageLocation("");
      }
    }
  }, [submittedFilter, selectedCompany, location, dispatch]);

  const handleChangeDataRange = (type: RangeType) => {
    const [fromDate, toDate] = getDateRange(type);
    setIsChanged((prev) => ({ ...prev, date: true }));
    setFilterRangeType(type);
    setFilter((prev) => ({
      ...prev,
      toDate: moment(toDate).format(),
      fromDate: moment(fromDate).format(),
    }));
  };

  const handleChangeViewType = (type: ChartGroupTypes) => {
    setIsChanged((prev) => ({ ...prev, group: true }));
    setFilterGroupType(type);
  };

  const handleUpdateDate = (fromDate: string, toDate: string) => {
    setIsChanged((prev) => ({ ...prev, date: true }));
    setFilter((prev) => ({
      ...prev,
      toDate,
      fromDate,
    }));
    setFilterRangeType(0);
  };

  const handleChangeDateType = (type: PeriodType) => {
    setIsChanged((prev) => ({ ...prev, periodType: true }));
    setFilter((prev) => ({ ...prev, periodType: type }));
  };

  const handleBranch = (branches: Array<{ value: string; label: string }>) => {
    setIsChanged((prev) => ({ ...prev, branch: true }));
    setFilterBranches(branches);
  };

  const handleLine = (lines: Array<{ value: string; label: string }>) => {
    setIsChanged((prev) => ({ ...prev, line: true }));
    setFilterLines(lines);
  };

  const handleGroupName = (
    groupNames: Array<{ value: string; label: string }>
  ) => {
    setIsChanged((prev) => ({ ...prev, line: true }));
    setFilterGroupNames(groupNames);
  };

  const handleFilterApply = () => {
    if (isChanged.group || isChanged.line || isChanged.branch) {
      dispatch(
        updateFrontSideFilter({
          group: filterGroupType,
          branches: filterBranches,
          lines: filterLines,
          groupNames: filterGroupNames,
        })
      );
    } else {
      dispatch(updateFilter(filter));
    }
    setIsChanged({
      date: false,
      group: false,
      periodType: false,
      line: false,
      branch: false,
      groupName: false,
    });
    dispatch(setBottomSheet(BottomSheetType.IDLE));
  };

  return (
    <>
      <Wrapper>
        <Logo />
        <ContentWrapper>
          <DropDownWrapper
            onClick={() => {
              dispatch(
                setBottomSheet(
                  bottomSheet === BottomSheetType.SWITCH_ACCOUNT
                    ? BottomSheetType.IDLE
                    : BottomSheetType.SWITCH_ACCOUNT
                )
              );
            }}
          >
            <IconV2 size="medium" iconName="company" />
            <ArrowWrapper>
              <IconV2 size="medium" iconName="arrowDown" />
            </ArrowWrapper>
          </DropDownWrapper>

          <DropDownWrapper
            onClick={() => {
              dispatch(
                setBottomSheet(
                  bottomSheet === BottomSheetType.PERSONAL_INFO
                    ? BottomSheetType.IDLE
                    : BottomSheetType.PERSONAL_INFO
                )
              );
            }}
          >
            <IconV2 size="medium" iconName="user" />
            <ArrowWrapper>
              <IconV2 size="medium" iconName="arrowDown" />
            </ArrowWrapper>
          </DropDownWrapper>
          <BurgerBarWrapper
            onClick={() => {
              dispatch(
                setBottomSheet(
                  bottomSheet === BottomSheetType.NAVIGATION
                    ? BottomSheetType.IDLE
                    : BottomSheetType.NAVIGATION
                )
              );
            }}
          >
            {bottomSheet === BottomSheetType.NAVIGATION ? (
              <IconV2 size="medium" iconName="closeIcon" />
            ) : (
              <IconV2 size="medium" iconName="burger" />
            )}
          </BurgerBarWrapper>
        </ContentWrapper>
      </Wrapper>
      {bottomSheet !== BottomSheetType.IDLE && (
        <BottomSheet
          filter={filter}
          isChanged={isChanged}
          frontSideFilter={frontSideFilter}
          filterRangeType={filterRangeType}
          pageLocation={pageLocation}
          filterLines={filterLines}
          filterBranches={filterBranches}
          filterGroupNames={filterGroupNames}
          filterGroupType={filterGroupType}
          openBottomSheet={bottomSheet}
          showViewFilter={showViewFilter}
          showBranchAndLineFilter={showBranchAndLineFilter}
          handleChangeDataRange={handleChangeDataRange}
          handleChangeViewType={handleChangeViewType}
          handleUpdateDate={handleUpdateDate}
          handleChangeDateType={handleChangeDateType}
          handleBranch={handleBranch}
          handleLine={handleLine}
          handleGroupName={handleGroupName}
          handleFilterApply={handleFilterApply}
          handleClick={(type: BottomSheetType) => {
            dispatch(setBottomSheet(type));
          }}
          handleClose={() => {
            dispatch(setBottomSheet(BottomSheetType.IDLE));
          }}
        />
      )}
    </>
  );
};

export default HeaderMobile;
