import React, { MouseEvent } from "react";
import {
  Content,
  ContentIconWrapper,
  IconWrapper,
  Wrapper,
} from "./Button.style";
import Icon, {
  IconNameOptions,
} from "../../../components/shared/iconV2/IconV2";

interface ButtonProps {
  text: string;
  size: "large" | "medium" | "small";
  buttonStyle: "filled" | "stroke" | "lighter" | "ghost";
  type?: "submit" | "button";
  loading?: boolean;
  disabled?: boolean;
  rounded?: boolean;
  leftIconName?: IconNameOptions | undefined;
  rightIconName?: IconNameOptions | undefined;
  center?: boolean;
  fullWidth?: boolean;
  handleClick?: (e: any) => void;
}
const Button = ({
  text,
  size,
  type = "button",
  buttonStyle,
  disabled = false,
  rounded = false,
  loading,
  leftIconName = undefined,
  rightIconName = undefined,
  center = false,
  fullWidth = false,
  handleClick,
}: ButtonProps) => {
  const handleFillIcon = (isFilled: boolean) => {
    if (disabled) return "disabled";
    return isFilled ? "white" : "primary";
  };
  return (
    <Wrapper
      type={type}
      rounded={rounded}
      disabled={disabled || Boolean(loading)}
      onClick={(e: MouseEvent<HTMLButtonElement>) => {
        if (loading) {
          return;
        }
        if (!disabled && handleClick) {
          handleClick(e);
        }
      }}
      size={size}
      buttonStyle={buttonStyle}
      center={center}
      fullWidth={fullWidth}
    >
      {loading ? (
        <Content
          buttonStyle={buttonStyle}
          rounded={rounded}
          disabled={loading || disabled}
        >
          <IconWrapper className="loading-svg">
            <Icon iconName="buttonLoader" size="normal" fill="disabled" />
          </IconWrapper>
        </Content>
      ) : (
        <>
          {leftIconName !== undefined && (
            <ContentIconWrapper rounded={rounded}>
              <Icon
                iconName={leftIconName}
                size={rounded ? "tiny" : "normal"}
                fill={handleFillIcon(buttonStyle === "filled")}
              />
            </ContentIconWrapper>
          )}
          <Content
            rounded={rounded}
            disabled={disabled}
            buttonStyle={buttonStyle}
          >
            {text}
          </Content>
          {rightIconName !== undefined && (
            <ContentIconWrapper rounded={rounded}>
              <Icon
                iconName={rightIconName}
                size={rounded ? "tiny" : "normal"}
                fill={handleFillIcon(buttonStyle === "filled")}
              />
            </ContentIconWrapper>
          )}
        </>
      )}
    </Wrapper>
  );
};

export default Button;
