import { FieldHelperProps, FieldInputProps, FieldMetaProps } from "formik";
import React, { useState } from "react";
import {
  Wrapper,
  CustomInput,
  Label,
  InputWrapper,
  ErrorWrapper,
  ErrorText,
  IconWrapper,
  CheckIconWrapper,
} from "./Input.style";
import Icon, { IconNameOptions } from "../../shared/iconV2/IconV2";

interface FormikFieldProps {
  field?: FieldInputProps<string>;
  meta?: FieldMetaProps<string>;
  helpers?: FieldHelperProps<string>;
}
interface InputProps {
  id: string;
  label?: string;
  placeholder?: string;
  value: string;
  errorText?: string;
  readonly?: boolean;
  isDisabled?: boolean;
  dotted?: boolean;
  iconName?: IconNameOptions | undefined;
  setValue: (test: string) => void;
}

type InputFormikProps = InputProps & FormikFieldProps;

const Input = ({
  id,
  label,
  placeholder,
  value,
  errorText,
  readonly = false,
  isDisabled = false,
  dotted = false,
  field,
  iconName,
  setValue,
}: InputFormikProps) => {
  const [focused, setFocused] = useState(false);

  const handleBlur = () => {
    setFocused(false);
  };

  const handleFocus = () => {
    setFocused(true);
  };

  const handleInput = (value: string) => {
    if (field) {
      field.onChange({ target: { name: field.name, value } });
    } else {
      setValue(value);
    }
  };

  return (
    <Wrapper>
      <InputWrapper onFocus={handleFocus} onBlur={handleBlur}>
        {label && (
          <Label
            hasError={Boolean(errorText)}
            isDisabled={isDisabled}
            isFocused={focused || !!value || !!field?.value}
            htmlFor={`${id}`}
          >
            {label}
          </Label>
        )}
        <CustomInput
          id={id}
          name={id}
          dotted={dotted}
          hasError={Boolean(errorText)}
          hasReadOnly={readonly}
          isDisabled={isDisabled || readonly}
          disabled={isDisabled || readonly}
          value={field?.value ?? value ?? ""}
          onChange={(e) => handleInput(e.target.value)}
          placeholder={placeholder}
          hasPlaceholder={!!placeholder}
        />
        {iconName && (
          <CheckIconWrapper>
            <Icon iconName="checkMark" size="medium" fill="primary" />
          </CheckIconWrapper>
        )}
      </InputWrapper>
      {Boolean(errorText) && (
        <ErrorWrapper>
          <IconWrapper>
            <Icon iconName="closeIcon" size="extraSmall" fill="white" />
          </IconWrapper>
          <ErrorText>{errorText}</ErrorText>
        </ErrorWrapper>
      )}
    </Wrapper>
  );
};

export default Input;
