import React, { ReactNode } from "react";
import Icon from "../../../../shared/iconV2/IconV2";

import {
  IconWrapper,
  Title,
  SubTitle,
  TitleWrapper,
  BodyContainer,
} from "./StepContainer.style";

interface StepContainerProps {
  title: string;
  subTitle: string;
  children: ReactNode;
  handleBack: () => void;
}
const StepContainer = ({
  title,
  subTitle,
  children,
  handleBack,
}: StepContainerProps) => {
  return (
    <div>
      <TitleWrapper>
        <IconWrapper onClick={handleBack}>
          <Icon iconName="leftArrow_v2" size="medium" />
        </IconWrapper>
        <Title>{title}</Title>
        <SubTitle>{subTitle}</SubTitle>
      </TitleWrapper>
      <BodyContainer>{children}</BodyContainer>
    </div>
  );
};

export default StepContainer;
