import {
  ContentWrapper,
  DottedArc,
  IconWrapper,
  ItemId,
  ItemTree,
  ItemTreeElement,
  ItemTreeElementContentWrapper,
  ItemTreeElementWrapper,
  ItemTreeIconWrapper,
  ItemTreeSubElement,
  ItemWrapper,
  Title,
  TitleWrapper,
  Wrapper,
} from "./MappingItems.style";

import { useTranslation } from "react-i18next";
import { MappingItemType } from "../../../../store/mapping/mapping.types";
import Icon from "../../../shared/iconV2/IconV2";
import MappingItemActions from "../../../v2/more/MappingItemActions";
import MappingDetailDynamicContent from "./MappingItemsDynamicContent";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";

interface MappingListItemProps {
  accountMappings: Array<MappingItemType>;
  orderingDisable: boolean;
  groupId: number;
  handleReorderItems: (items: Array<number>) => void;
  handleOpenEditItem: (mappingItem: MappingItemType) => void;
  handleDeleteMappingItem: (id: number, name: string, groupId: number) => void;
}
const List = ({
  groupId,
  accountMappings = [],
  orderingDisable,
  handleOpenEditItem,
  handleReorderItems,
  handleDeleteMappingItem,
}: MappingListItemProps) => {
  const { t } = useTranslation();

  const reorder = (list: any[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;
    const { source, destination } = result;
    const reorderedGroupMappingItems = reorder(
      accountMappings,
      source.index,
      destination.index
    );
    const reorderedGroupMappingItemIds = reorderedGroupMappingItems.map(
      (item) => item.id
    );
    handleReorderItems(reorderedGroupMappingItemIds);
  };

  if (!Array.isArray(accountMappings)) return null;

  return (
    <Wrapper>
      <DragDropContext onDragEnd={onDragEnd}>
        {accountMappings.map((item, itemIndex) => (
          <Droppable
            key={`droppable-Item-${item.id}`}
            droppableId={`droppable-Item-${item.id}`}
            isDropDisabled={orderingDisable}
          >
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                <Draggable
                  key={`Item-${item.id}`}
                  draggableId={`droppable-Item-${item.id}`}
                  index={itemIndex}
                  isDragDisabled={orderingDisable}
                >
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.draggableProps}>
                      <ItemWrapper key={item.id}>
                        <IconWrapper {...provided.dragHandleProps}>
                          <Icon iconName="drag_v2" size="medium" />
                        </IconWrapper>
                        <ContentWrapper>
                          <div>
                            <TitleWrapper>
                              <ItemId>{item.accountId}</ItemId> -{" "}
                              <Title> {item.name}</Title>
                              {(!item.debitRePortalMapping?.name ||
                                !item.creditRePortalMapping?.name) && (
                                <Icon iconName="alert" size="tiny" />
                              )}
                            </TitleWrapper>
                            {(item.debitRePortalMapping?.name ||
                              item.creditRePortalMapping?.name ||
                              item.companyBranch?.name ||
                              item.companyBusinessLine?.name) && (
                              <ItemTree>
                                {item.debitRePortalMapping?.name && (
                                  <ItemTreeElementWrapper>
                                    <ItemTreeIconWrapper>
                                      <DottedArc />
                                    </ItemTreeIconWrapper>
                                    <ItemTreeElementContentWrapper>
                                      <ItemTreeElement>
                                        {t("Mapping.DebitTitle")}
                                      </ItemTreeElement>
                                      <ItemTreeSubElement>
                                        {item.debitRePortalMapping?.name}
                                      </ItemTreeSubElement>
                                    </ItemTreeElementContentWrapper>
                                  </ItemTreeElementWrapper>
                                )}

                                {item.creditRePortalMapping?.name && (
                                  <ItemTreeElementWrapper>
                                    <ItemTreeIconWrapper>
                                      <DottedArc />
                                    </ItemTreeIconWrapper>
                                    <ItemTreeElementContentWrapper>
                                      <ItemTreeElement>
                                        {t("Mapping.CreditTitle")}
                                      </ItemTreeElement>
                                      <ItemTreeSubElement>
                                        {item.creditRePortalMapping?.name}
                                      </ItemTreeSubElement>
                                    </ItemTreeElementContentWrapper>
                                  </ItemTreeElementWrapper>
                                )}

                                {item.companyBranch?.name && (
                                  <ItemTreeElementWrapper>
                                    <ItemTreeIconWrapper>
                                      <DottedArc />
                                    </ItemTreeIconWrapper>
                                    <ItemTreeElementContentWrapper>
                                      <ItemTreeElement>
                                        {t("Mapping.Branch")}
                                      </ItemTreeElement>
                                      <ItemTreeSubElement>
                                        {item.companyBranch?.name}
                                      </ItemTreeSubElement>
                                    </ItemTreeElementContentWrapper>
                                  </ItemTreeElementWrapper>
                                )}

                                {item.companyBusinessLine?.name && (
                                  <ItemTreeElementWrapper>
                                    <ItemTreeIconWrapper>
                                      <DottedArc />
                                    </ItemTreeIconWrapper>
                                    <ItemTreeElementContentWrapper>
                                      <ItemTreeElement>
                                        {t("Mapping.BusinessLine")}
                                      </ItemTreeElement>
                                      <ItemTreeSubElement>
                                        {item.companyBusinessLine?.name}
                                      </ItemTreeSubElement>
                                    </ItemTreeElementContentWrapper>
                                  </ItemTreeElementWrapper>
                                )}
                              </ItemTree>
                            )}
                          </div>
                        </ContentWrapper>
                        <MappingItemActions
                          handleEdit={() => handleOpenEditItem(item)}
                          handleDelete={() =>
                            handleDeleteMappingItem(item.id, item.name, groupId)
                          }
                        />
                      </ItemWrapper>
                    </div>
                  )}
                </Draggable>
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        ))}
      </DragDropContext>
    </Wrapper>
  );
};

interface MappingItemsProps {
  accountMappings: Array<MappingItemType>;
  orderingDisable: boolean;
  groupId: number;
  handleReorderItems: (items: Array<number>) => void;
  handleOpenEditItem: (mappingItem: MappingItemType) => void;
  handleDeleteMappingItem: (id: number, name: string, groupId: number) => void;
}

const MappingItems = ({
  accountMappings,
  orderingDisable,
  groupId,
  handleReorderItems,
  handleOpenEditItem,
  handleDeleteMappingItem,
}: MappingItemsProps) => {
  if (accountMappings.length < 30) {
    return (
      <List
        accountMappings={accountMappings}
        orderingDisable={orderingDisable}
        groupId={groupId}
        handleReorderItems={handleReorderItems}
        handleOpenEditItem={handleOpenEditItem}
        handleDeleteMappingItem={handleDeleteMappingItem}
      />
    );
  }

  return (
    <Wrapper>
      <MappingDetailDynamicContent
        accountMappings={accountMappings}
        groupId={groupId}
        handleOpenEditItem={handleOpenEditItem}
        handleDeleteMappingItem={handleDeleteMappingItem}
      />
    </Wrapper>
  );
};

export default MappingItems;
