import React from "react";
import {
  AlertContent,
  CloseIconWrapper,
  IconWrapper,
  Wrapper,
} from "./Alert.style";
import Icon from "../iconV2/IconV2";

interface AlertProps {
  status: "Success" | "Error";
  message: string;
  handleClose?: () => void;
}
const Alert = ({ status, message, handleClose }: AlertProps) => {
  return (
    <Wrapper status={status}>
      {status === "Success" ? (
        <IconWrapper status="Success">
          <Icon fill="white" size="tiny" iconName="checkMark" />
        </IconWrapper>
      ) : (
        <IconWrapper status="Error">
          <Icon fill="white" iconName="closeIcon" size="tiny" />
        </IconWrapper>
      )}
      <AlertContent status={status}>{message}</AlertContent>
      <CloseIconWrapper onClick={handleClose}>
        <Icon iconName="closeIcon" size="normal" />
      </CloseIconWrapper>
    </Wrapper>
  );
};

export default Alert;
