import { ReactComponent as cashFlow } from "../../../assets/svgs/cashFlow.svg";
import { ReactComponent as arrowDown } from "../../../assets/svgs/arrowDown.svg";
import { ReactComponent as arrowUp } from "../../../assets/svgs/arrowUp.svg";
import { ReactComponent as closeIcon } from "../../../assets/svgs/closeIcon.svg";
import { ReactComponent as company } from "../../../assets/svgs/company.svg";
import { ReactComponent as folder } from "../../../assets/svgs/folder.svg";
import { ReactComponent as expenses } from "../../../assets/svgs/expenses.svg";
import { ReactComponent as help } from "../../../assets/svgs/help.svg";
import { ReactComponent as language } from "../../../assets/svgs/language.svg";
import { ReactComponent as burger } from "../../../assets/svgs/burger.svg";
import { ReactComponent as profitLoss } from "../../../assets/svgs/profitLoss.svg";
import { ReactComponent as report } from "../../../assets/svgs/report.svg";
import { ReactComponent as reportal } from "../../../assets/svgs/reportal.svg";
import { ReactComponent as revenue } from "../../../assets/svgs/revenue.svg";
import { ReactComponent as rsGe } from "../../../assets/svgs/rs.svg";
import { ReactComponent as upDownArrows } from "../../../assets/svgs/upDownArrows.svg";
import { ReactComponent as user } from "../../../assets/svgs/user.svg";
import { ReactComponent as people } from "../../../assets/svgs/people.svg";
import { ReactComponent as password } from "../../../assets/svgs/password.svg";
import { ReactComponent as logOut } from "../../../assets/svgs/logout.svg";
import { ReactComponent as ge } from "../../../assets/svgs/ge.svg";
import { ReactComponent as en } from "../../../assets/svgs/en.svg";
import { ReactComponent as search } from "../../../assets/svgs/search.svg";
import { ReactComponent as checkMark } from "../../../assets/svgs/checkMark.svg";
import { ReactComponent as fav } from "../../../assets/svgs/fav.svg";
import { ReactComponent as favFilled } from "../../../assets/svgs/favFilled.svg";
import { ReactComponent as noResult } from "../../../assets/svgs/noResult.svg";
import { ReactComponent as market } from "../../../assets/svgs/market.svg";
import { ReactComponent as excel } from "../../../assets/svgs/excel.svg";

import { ReactComponent as drag_v2 } from "../../../assets/svgs/drag_v2.svg";
import { ReactComponent as pen } from "../../../assets/svgs/pen.svg";
import { ReactComponent as trash_v2 } from "../../../assets/svgs/trash.svg";
import { ReactComponent as alert } from "../../../assets/svgs/alert.svg";
import { ReactComponent as leftArrow_v2 } from "../../../assets/svgs/left_v2.svg";
import { ReactComponent as dots } from "../../../assets/svgs/dots.svg";
import { ReactComponent as plus_v2 } from "../../../assets/svgs/plus_v2.svg";
import { ReactComponent as redClose } from "../../../assets/svgs/redClose.svg";
import { ReactComponent as buttonLoader } from "../../../assets/svgs/loader.svg";
import { ReactComponent as searchSmall } from "../../../assets/svgs/search_small.svg";

const CustomIcons = {
  cashFlow,
  arrowDown,
  arrowUp,
  closeIcon,
  company,
  folder,
  expenses,
  help,
  language,
  burger,
  profitLoss,
  report,
  reportal,
  revenue,
  rsGe,
  upDownArrows,
  user,
  people,
  password,
  logOut,
  ge,
  en,
  search,
  checkMark,
  favFilled,
  fav,
  noResult,
  market,
  excel,
  plus_v2,
  drag_v2,
  pen,
  trash_v2,
  alert,
  leftArrow_v2,
  dots,
  redClose,
  buttonLoader,
  searchSmall,
};

export default CustomIcons;
