import React from "react";
import Icon from "../../../shared/iconV2/IconV2";
import { Wrapper } from "./InformationNumber.style";

interface InformationNumberProps {
  quantity?: number;
  warningQuantity?: number;
}
const InformationNumber = ({
  quantity,
  warningQuantity,
}: InformationNumberProps) => {
  if (
    quantity === undefined &&
    (warningQuantity === undefined || !warningQuantity)
  )
    return null;

  return (
    <Wrapper hasWarning={!!warningQuantity}>
      {warningQuantity && <Icon size="tiny" iconName="alert" />}
      {quantity !== undefined ? quantity : warningQuantity}
    </Wrapper>
  );
};

export default InformationNumber;
