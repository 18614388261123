import React from "react";
import {
  ButtonWrapper,
  InfoContentWrapper,
  InfoWrapper,
  UnMappedIconWrapper,
  UnMappedSubTitle,
  UnMappedTitle,
  UnMappedWrapper,
} from "./UnmappedGroup.style";
import Icon from "../../../../components/shared/iconV2/IconV2";

import { useTranslation } from "react-i18next";
import Button from "../../../v2/button/Button";

interface UnMappedProps {
  itemNumber: number;
  openDetailModal: () => void;
  handleOpenAddMapping: () => void;
}
const UnmappedGroup = ({
  itemNumber,
  openDetailModal,
  handleOpenAddMapping,
}: UnMappedProps) => {
  const { t } = useTranslation();
  return (
    <UnMappedWrapper>
      <InfoWrapper>
        <UnMappedIconWrapper>
          <Icon iconName="redClose" size="normal" />
        </UnMappedIconWrapper>
        <InfoContentWrapper>
          <UnMappedTitle>
            {t("UnMapped.TitleFirstHalf")} {itemNumber}{" "}
            {t("UnMapped.TitleLastHalf")}
          </UnMappedTitle>
          <UnMappedSubTitle> {t("UnMapped.SubTitle")}</UnMappedSubTitle>
        </InfoContentWrapper>
      </InfoWrapper>
      <ButtonWrapper>
        <Button
          text={t("UnMapped.ViewDetailsTitle")}
          size="medium"
          buttonStyle="ghost"
          handleClick={openDetailModal}
        />
        <Button
          text={t("UnMapped.MappingItemTitle")}
          size="medium"
          leftIconName="plus_v2"
          buttonStyle="filled"
          handleClick={handleOpenAddMapping}
        />
      </ButtonWrapper>
    </UnMappedWrapper>
  );
};

export default UnmappedGroup;
