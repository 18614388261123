import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import ExcelImage from "../../../../assets/images/excel.png";
import Modal from "../../../shared/modal/Modal";

import {
  Description,
  FileType,
  Span,
  SvgContainer,
  ChooseFileWrapper,
  CardContainer,
  CloseSvg,
  FileImage,
  FileName,
  FileSize,
  FileWrapper,
  ProgressContainer,
  ButtonWrapper,
  AlertWrapper,
  DropDownWrapper,
} from "./UploadModal.style";
import Button from "../../../shared/button/Button";
import { formatToMb } from "../../../../helpers/importDataHelper";
import Alert from "../../../shared/alert/Alert";
import { generateFileYearsData } from "../../../../helpers/helper";
import Dropdown from "../../../shared/dropdown/Dropdown";
import Icon from "../../../shared/icon/Icon";

interface UploadModalProps {
  isLoading: boolean;
  errorMessage: string;
  hasYear: boolean;
  handleClose: () => void;
  handleSubmit: (file: File, year: string | null) => void;
}

const UploadModal = ({
  hasYear,
  isLoading,
  errorMessage,
  handleClose,
  handleSubmit,
}: UploadModalProps) => {
  const { t } = useTranslation();

  const [selectedFile, setSelectedFile] = useState<any>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [selectedYear, setSelectedYear] = useState("");

  const handleFileSelect = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
    }
  };

  return (
    <Modal
      type="medium"
      title={t("UploadModal.Title")}
      close={handleClose}
      showCloseIcon={false}
      headerContent={
        hasYear ? (
          <DropDownWrapper>
            <Dropdown
              title={t("UploadModal.Years")}
              options={generateFileYearsData()}
              selectedValue={selectedYear}
              handleSelect={setSelectedYear}
            />
          </DropDownWrapper>
        ) : null
      }
    >
      <ChooseFileWrapper>
        <SvgContainer
          onClick={() => {
            fileInputRef.current?.click();
          }}
        >
          <Icon iconName="plus" />
          {/* <PlusSvg /> */}
        </SvgContainer>
        <Description>
          <input
            type="file"
            accept=".xls,.xlsx"
            onChange={handleFileSelect}
            style={{ display: "none" }}
            ref={fileInputRef}
          />
          <Span
            onClick={() => {
              fileInputRef.current?.click();
            }}
          >
            {t("UploadModal.SubTitleLink")}
          </Span>
          {t("UploadModal.SubTitle")}
        </Description>
        <FileType>{t("UploadModal.Description")}</FileType>
      </ChooseFileWrapper>

      {selectedFile && (
        <ProgressContainer>
          <CardContainer>
            <FileWrapper>
              <FileImage src={ExcelImage} alt={t("UploadModal.ImageAlt")} />
              <div>
                <FileName>{selectedFile?.name}</FileName>
                <FileSize>{formatToMb(selectedFile?.size)} MB</FileSize>
              </div>
            </FileWrapper>
            <CloseSvg
              onClick={() => {
                setSelectedFile(null);
              }}
            />
          </CardContainer>
        </ProgressContainer>
      )}

      {errorMessage && (
        <AlertWrapper>
          <Alert status="Error" message={errorMessage} />
        </AlertWrapper>
      )}

      <ButtonWrapper>
        <Button
          title={t("UploadModal.CancelButton")}
          isDisabled={false}
          isLoading={false}
          onClick={handleClose}
          buttonType="outlined"
        />
        <Button
          title={t("UploadModal.ImportButton")}
          isDisabled={
            !Boolean((selectedYear || !hasYear) && selectedFile !== null)
          }
          isLoading={isLoading}
          onClick={() => {
            handleSubmit(selectedFile, selectedYear || null);
          }}
        />
      </ButtonWrapper>
    </Modal>
  );
};
export default UploadModal;
