import { t } from "i18next";
import Icon from "../../../../../shared/iconV2/IconV2";
import Button from "../../../../../v2/button/Button";
import { Item, Wrapper } from "./ItemType.style";

interface Props {
  typeOptions: Array<{ value: string; label: string }>;
  typeId: string;
  handleNext: () => void;
  updateTypeId: (typeId: string) => void;
}
const ItemType = ({ typeId, typeOptions, updateTypeId, handleNext }: Props) => {
  return (
    <Wrapper>
      {typeOptions.map((item) => (
        <Item
          isSelected={item.value === typeId}
          onClick={() => updateTypeId(item.value)}
          key={item.value}
        >
          {item.label}
          {item.value === typeId && (
            <Icon iconName="checkMark" size="medium" fill="primary" />
          )}
        </Item>
      ))}
      <Button
        disabled={!typeId}
        text={t("AddMapping.NextStep")}
        size="large"
        buttonStyle="filled"
        handleClick={() => {
          handleNext();
        }}
        center
        fullWidth
      />
    </Wrapper>
  );
};

export default ItemType;
