import React from "react";
import Icon from "../icon/Icon";
import { LoaderIconWrapper } from "./Loader.style";

const Loader = () => (
  <LoaderIconWrapper>
    <Icon iconName="loader" size="extraLarge" />
  </LoaderIconWrapper>
);

export default Loader;
