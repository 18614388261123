import React, { useEffect, useState } from "react";
import {
  BodyWrapper,
  Header,
  InputWrapper,
  Item,
  ItemWrapper,
  MainHeader,
  MainHeaderTitle,
  Section,
  Title,
  Wrapper,
} from "./MappingLoading.style";

const ItemHeight = 429;

const MappingLoading = ({ height }: { height: number }) => {
  const [numberArray, setNumberArray] = useState<number[]>([]);

  useEffect(() => {
    const tempArray = Array.from(
      { length: 7 },
      () => Math.floor(Math.random() * 351) + 100
    );
    setNumberArray(tempArray);
  }, []);

  return (
    <>
      <MainHeader>
        <MainHeaderTitle />
      </MainHeader>
      <Wrapper>
        <Header>
          <InputWrapper />
        </Header>
        <Section>
          {Array(+(height / ItemHeight).toFixed(0) || 2)
            .fill(1)
            .map((item, index) => (
              <div key={index}>
                <Title />
                <BodyWrapper>
                  {numberArray.map((number, index) => (
                    <ItemWrapper key={index}>
                      <Item length={number} />
                    </ItemWrapper>
                  ))}
                </BodyWrapper>
              </div>
            ))}
        </Section>
      </Wrapper>
    </>
  );
};

export default MappingLoading;
