import { t } from "i18next";
import Icon from "../../../../../shared/iconV2/IconV2";
import Button from "../../../../../v2/button/Button";
import { Item, Wrapper } from "./ItemGroup.style";
import Input from "../../../../../v2/input/Input";
import { LoaderType } from "../../../../../../store/type";

interface Props {
  groupOptions: Array<{ value: string; label: string }>;
  groupId: string;
  groupName: string;
  createStatus: LoaderType;
  handleNext: () => void;
  updateGroupId: (groupId: string) => void;
  updateGroupName: (groupName: string) => void;
}
const ItemGroup = ({
  groupId,
  groupName,
  groupOptions,
  createStatus,
  updateGroupId,
  updateGroupName,
  handleNext,
}: Props) => {
  return (
    <Wrapper>
      {groupOptions.map((item) => (
        <Item
          isSelected={item.value === groupId}
          onClick={() => {
            updateGroupId(item.value);
            updateGroupName("");
          }}
          key={item.value}
        >
          {item.label}
          {item.value === groupId && (
            <Icon iconName="checkMark" size="medium" fill="primary" />
          )}
        </Item>
      ))}
      <Input
        id={"groupName"}
        dotted={!groupName}
        iconName={groupName ? "checkMark" : undefined}
        placeholder={t("AddMapping.DottedInputText")}
        value={groupName}
        setValue={(value) => {
          updateGroupId("");
          updateGroupName(value);
        }}
      />
      <Button
        disabled={!groupId && !groupName}
        text={t("AddMapping.NextStep")}
        size="large"
        buttonStyle="filled"
        loading={createStatus === "pending"}
        handleClick={handleNext}
        center
        fullWidth
      />
    </Wrapper>
  );
};

export default ItemGroup;
