import { t } from "i18next";
import Icon from "../../../../../shared/iconV2/IconV2";
import Button from "../../../../../v2/button/Button";
import { Item, Wrapper } from "./ItemGroupType.style";

interface Props {
  groupTypeOptions: Array<{ value: string; label: string }>;
  groupeTypeId: string;
  handleNext: () => void;
  updateGroupeTypeId: (groupTypeId: string) => void;
}
const ItemGroupType = ({
  groupeTypeId,
  groupTypeOptions,
  updateGroupeTypeId,
  handleNext,
}: Props) => {
  return (
    <Wrapper>
      {groupTypeOptions.map((item) => (
        <Item
          isSelected={item.value === groupeTypeId}
          onClick={() => updateGroupeTypeId(item.value)}
          key={item.value}
        >
          {item.label}
          {item.value === groupeTypeId && (
            <Icon iconName="checkMark" size="medium" fill="primary" />
          )}
        </Item>
      ))}
      <Button
        disabled={!groupeTypeId}
        text={t("AddMapping.NextStep")}
        size="large"
        buttonStyle="filled"
        handleClick={handleNext}
        center
        fullWidth
      />
    </Wrapper>
  );
};

export default ItemGroupType;
