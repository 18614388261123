import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import IconV2 from "../../../shared/iconV2/IconV2";
import {
  ArrowIconWrapper,
  ExamplesItem,
  ExamplesWrapper,
  HoverItem,
  Title,
  TitleWrapper,
  Wrapper,
} from "./SideBarNavigationWithDropdown.style";

import { useLocation, useNavigate } from "react-router-dom";
import { PageURL } from "../../../../constants/router";
import { isMobile } from "react-device-detect";
interface SideBarDropDownProps {
  active: boolean;
  setOpen: (state: boolean) => void;
  handleClose?: () => void;
}
const SideBarDropDown = ({
  active,
  setOpen,
  handleClose,
}: SideBarDropDownProps) => {
  const [openDropDown, setOpenDropDown] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    if (
      location.pathname !== "/app/import-data" &&
      location.pathname !== "/app/Mapping"
    ) {
      setOpenDropDown(false);
    }
  }, [location]);

  useEffect(() => {
    if (!active) {
      setOpenDropDown(false);
    }
  }, [active]);

  const handleOpenSideBar = () => {
    if (!active) {
      setOpen(true);
    }
  };
  return (
    <>
      <Wrapper
        isMobile={isMobile}
        active={active}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        openDropdown={
          location.pathname === "/app/import-data" ||
          location.pathname === "/app/Mapping"
        }
        onClick={() => {
          handleOpenSideBar();
          if (active) {
            setOpenDropDown((prev) => !prev);
          } else {
            setTimeout(() => {
              setOpenDropDown((prev) => !prev);
            }, 200);
          }
        }}
      >
        <TitleWrapper>
          <IconV2
            size="medium"
            fill={
              location.pathname === "/app/import-data" ||
              location.pathname === "/app/Mapping"
                ? "primary"
                : "default"
            }
            iconName="folder"
          />
          <Title
            isMobile={isMobile}
            active={active}
            openDropdown={
              location.pathname === "/app/import-data" ||
              location.pathname === "/app/Mapping"
            }
          >
            {t("SideBar.Data")}
          </Title>
        </TitleWrapper>
        <ArrowIconWrapper
          active={active}
          className={openDropDown ? "rotate" : ""}
        >
          <IconV2
            fill={
              location.pathname === "/app/import-data" ||
              location.pathname === "/app/Mapping"
                ? "primary"
                : "default"
            }
            size="medium"
            iconName="arrowDown"
          />
        </ArrowIconWrapper>
        {isHovered && !active && <HoverItem>{t("SideBar.Data")}</HoverItem>}
      </Wrapper>
      {openDropDown && (
        <ExamplesWrapper openDropdown={openDropDown}>
          <ExamplesItem
            isMobile={isMobile}
            clicked={location.pathname.includes(PageURL.ImportData.path)}
            onClick={() => {
              navigate(PageURL.ImportData.path);
              if (handleClose) handleClose();
            }}
          >
            {t("SideBar.ImportData")}
          </ExamplesItem>
          <ExamplesItem
            isMobile={isMobile}
            clicked={location.pathname.includes(PageURL.Mapping.path)}
            onClick={() => {
              navigate(PageURL.Mapping.path);
              if (handleClose) handleClose();
            }}
          >
            {t("SideBar.Mapping")}
          </ExamplesItem>
        </ExamplesWrapper>
      )}
    </>
  );
};

export default SideBarDropDown;
