import React from "react";
import IconV2, { IconNameOptions } from "../../../../shared/iconV2/IconV2";
import { Title, Wrapper } from "./PersonalDataItem.style";

interface PersonalDataItemProps {
  iconName: IconNameOptions;
  title: string;
  isLogOut?: boolean;
  handleClick: () => void;
}
const PersonalDataItem = ({
  iconName,
  title,
  isLogOut = false,
  handleClick,
}: PersonalDataItemProps) => {
  return (
    <Wrapper onClick={handleClick} isLogOut={isLogOut}>
      <IconV2 iconName={iconName} />
      <Title isLogOut={isLogOut}>{title}</Title>
    </Wrapper>
  );
};

export default PersonalDataItem;
