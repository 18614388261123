import React from "react";
import { useTranslation } from "react-i18next";

// import { ReactComponent as UploadSvg } from "../../../assets/svgs/upload.svg";
import Button from "../../../shared/button/Button";
import Icon from "../../../shared/icon/Icon";
import {
  ButtonWrapper,
  SvgWrapper,
  Title,
  Wrapper,
} from "./ImportExcelCard.style";

interface UploadProps {
  title: string;

  disabled?: boolean;
  handleOpenUpload: () => void;
}
const ImportExcelCard = ({
  title,
  disabled = false,
  handleOpenUpload,
}: UploadProps) => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <SvgWrapper>
        {/* <UploadSvg /> */}
        <Icon iconName="upload" />
      </SvgWrapper>
      <Title>{title}</Title>
      <ButtonWrapper>
        <Button
          title={t("ImportExcelCard.ButtonTitle")}
          isDisabled={disabled}
          isLoading={false}
          onClick={handleOpenUpload}
        />
      </ButtonWrapper>
    </Wrapper>
  );
};

export default ImportExcelCard;
