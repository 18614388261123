import { ReactComponent as calendar } from "../../../assets/svgs/calendar.svg";
import { ReactComponent as rightArrow } from "../../../assets/svgs/rightArrow.svg";
import { ReactComponent as download } from "../../../assets/svgs/download.svg";
import { ReactComponent as plus } from "../../../assets/svgs/plus.svg";
import { ReactComponent as drag } from "../../../assets/svgs/drag.svg";
import { ReactComponent as upload } from "../../../assets/svgs/upload.svg";
import { ReactComponent as dashboardBurger } from "../../../assets/svgs/burgerBarDashboard.svg";
import { ReactComponent as loader } from "../../../assets/svgs/tableLoader.svg";
import { ReactComponent as navigationBurger } from "../../../assets/svgs/burgerBarNavigation.svg";
import { ReactComponent as help } from "../../../assets/svgs/help.svg";
import { ReactComponent as profile } from "../../../assets/svgs/profileIcon.svg";
import { ReactComponent as close } from "../../../assets/svgs/close.svg";
import { ReactComponent as mobileFilter } from "../../../assets/svgs/mobileFilter.svg";
import { ReactComponent as en } from "../../../assets/svgs/en.svg";
import { ReactComponent as ge } from "../../../assets/svgs/ge.svg";
import { ReactComponent as leftArrow } from "../../../assets/svgs/leftArrow.svg";
import { ReactComponent as edit } from "../../../assets/svgs/edit.svg";
import { ReactComponent as deleteIcon } from "../../../assets/svgs/delete.svg";

const CustomIcons = {
  calendar,
  rightArrow,
  download,
  plus,
  drag,
  upload,
  dashboardBurger,
  loader,
  navigationBurger,
  help,
  profile,
  close,
  mobileFilter,
  en,
  ge,
  leftArrow,
  edit,
  deleteIcon,
};

export default CustomIcons;
