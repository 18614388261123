import React, { useCallback, useState } from "react";
import {
  AccountMappingGroup,
  MappingItemType,
} from "../../../../store/mapping/mapping.types";

import Icon from "../../../shared/iconV2/IconV2";

import MappingItems from "../mappingItems/MappingItems";

import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";

import {
  IconWrapper,
  InfoWrapper,
  ListItem,
  ListTitle,
  TitleWrapper,
  Wrapper,
} from "./MappingGroups.style";
import MappingItemActions from "../../../v2/more/MappingItemActions";

interface MappingGroupsProps {
  openGroupId: number | null;
  setOpenGroupId: (state: number | null) => void;
  groups: Array<AccountMappingGroup>;
  orderingDisable: boolean;
  handleOpenEditItem: (mappingItem: MappingItemType) => void;
  handleDeleteGroup: (
    name: string,
    groupId: number,
    accountMappingTypeId: number
  ) => void;
  handleEditGroup: (group: AccountMappingGroup) => void;
  updateGroupsOrdering: (groupsIds: Array<number>) => void;
  handleDeleteMappingItem: (
    id: number,
    name: string,
    sectionId: number,
    accountMappingTypeId: number
  ) => void;
  updateGroupMappingItemOrdering: (ids: Array<number>, groupId: number) => void;
}

const MappingGroups = ({
  openGroupId,
  groups,
  orderingDisable,
  setOpenGroupId,
  handleOpenEditItem,
  handleEditGroup,
  updateGroupsOrdering,
  handleDeleteGroup,
  handleDeleteMappingItem,
  updateGroupMappingItemOrdering,
}: MappingGroupsProps) => {
  const handleOpenGroup = (id: number) => {
    if (openGroupId === id) {
      setOpenGroupId(null);
    } else {
      setOpenGroupId(id);
    }
  };

  const warningAccountMappingNumber = useCallback(
    (accountMappingGroup: AccountMappingGroup): number => {
      return accountMappingGroup.accountMappings.findIndex(
        (item) => !item.creditRePortalMappingId || !item.debitRePortalMappingId
      );
    },
    []
  );

  const reorder = (list: any[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;
    const { source, destination } = result;
    const reorderTypeGroups = reorder(groups, source.index, destination.index);
    const reorderTypeGroupIds = reorderTypeGroups.map((group) => group.id);
    updateGroupsOrdering(reorderTypeGroupIds);
  };

  return (
    <Wrapper>
      <DragDropContext onDragEnd={onDragEnd}>
        {groups.map((group, groupIndex) => (
          <Droppable
            key={`droppable-${group.id}`}
            droppableId={`droppable-${group.id}`}
            isDropDisabled={orderingDisable}
          >
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                <Draggable
                  key={group.id}
                  draggableId={`group-${group.id}`}
                  index={groupIndex}
                  isDragDisabled={orderingDisable}
                >
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.draggableProps}>
                      <ListItem
                        hasBorder={groupIndex !== groups.length - 1}
                        onClick={() => handleOpenGroup(group.id)}
                      >
                        <TitleWrapper>
                          <IconWrapper {...provided.dragHandleProps}>
                            <Icon iconName="drag_v2" size="medium" />
                          </IconWrapper>
                          <ListTitle>{group.name}</ListTitle>
                          {warningAccountMappingNumber(group) > -1 && (
                            <Icon iconName="alert" size="tiny" />
                          )}
                        </TitleWrapper>
                        <InfoWrapper>
                          <MappingItemActions
                            hasDelete={!group.accountMappings?.length}
                            handleDelete={() => {
                              handleDeleteGroup(
                                group.name,
                                group.id,
                                group.accountMappingTypeId
                              );
                            }}
                            handleEdit={() => {
                              handleEditGroup(group);
                            }}
                          />
                          <IconWrapper
                            className={openGroupId === group.id ? "rotate" : ""}
                          >
                            <Icon
                              iconName="arrowDown"
                              size="medium"
                              fill="grey"
                            />
                          </IconWrapper>
                        </InfoWrapper>
                      </ListItem>
                      {openGroupId === group.id && (
                        <MappingItems
                          orderingDisable={orderingDisable}
                          groupId={group.id}
                          handleOpenEditItem={handleOpenEditItem}
                          handleReorderItems={(mappingIds) => {
                            updateGroupMappingItemOrdering(
                              mappingIds,
                              group.id
                            );
                          }}
                          handleDeleteMappingItem={(id, name, groupId) => {
                            handleDeleteMappingItem(
                              id,
                              name,
                              groupId,
                              group.accountMappingTypeId
                            );
                          }}
                          accountMappings={group.accountMappings}
                        />
                      )}
                    </div>
                  )}
                </Draggable>
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        ))}
      </DragDropContext>
    </Wrapper>
  );
};

export default MappingGroups;
