import React from "react";

import CustomIcons from "./IconSvgs";

const sizeMap: Record<string, { width: number; height: number }> = {
  tiny: { width: 10, height: 10 },
  small: { width: 17, height: 17 },
  medium: { width: 34, height: 34 },
  large: { width: 48, height: 32 },
  extraLarge: { width: 100, height: 100 },
};

export type IconNameOptions = keyof typeof CustomIcons;

export interface CustomIconProps {
  iconName: IconNameOptions;
  size?: "tiny" | "small" | "medium" | "large" | "extraLarge";
}

const Icon = ({ iconName, size = "small" }: CustomIconProps) => {
  const IconComponent = CustomIcons[iconName];

  const { width, height } = sizeMap[size];

  if (!IconComponent) {
    return null;
  }

  return <IconComponent width={width} height={height} />;
};

export default Icon;
